@import './fonts.scss';
@import '~react-datepicker/dist/react-datepicker.css';
@import './react-select-search.scss';

$primary: #14c47d;
$secondary: #1733dd;
$text: #000000;
$direction: #828282;
$white: #ffffff;

/* Status */
$complete: #1733dd;
$incomplete: #fc7f2f;
$notReferred: #8f8f8f;

img {
  max-width: 100%;
}

body {
  margin: 0;
  font-family: 'Nunito Sans', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: $white;
}

#root {
  display: block;
  margin: 0 auto;
  color: $text;
}

@mixin button-list {
  width: 100%;
  padding: 90px 10px 15px;
  background-color: rgba(255, 255, 255, 0.8);
  z-index: 100;
}

.app-header {
  width: 100%;
  background-color: #fff;
  z-index: 999;

  .app-back, .app-home {
    color: $text;
    padding: 7px;
  }
  .app-title {
    font-size: 20px;
    font-style: normal;
    font-weight: bold;
    color: $text;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .push-to-right {
    padding-left: 25px;
  }
}

.app-main {
  padding-top: 47px;
}

.eqha-banner {
  text-align: center;

  & > img {
    object-fit: cover;
    width: 100%;
    height: auto;
  }
}

.home {
  padding: 20px 0 0;
  .content {
    text-align: left;

    p {
      text-align: left;
      font-size: 0.875rem;
      line-height: 150%;
      padding: 0 23px;
    }
  }

  .button-list {
    @include button-list();
      @media (min-height: 798px) {
      padding-top: 160px;
    }
  }
}

.button-default {
  width: 100%;
  display: block;
  margin: 15px auto;
  padding: 15px;
  color: $white;
  font-weight: bold;
  font-style: normal;
  font-size: 12px;
  max-width: 260px;
  border-radius: 50px;
  border: none;

  &.primary {
    background-color: $primary;
  }

  &.secondary {
    background-color: $secondary;
  }

  &.outlined {
    border: 1px solid #a5a5a5;
    border-radius: 50px;
    background-color: transparent;
    color: $text;
  }
}

.success-alert {
  width: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 30px;
  text-align: center;

  img {
    width: 70px;
    margin: 0 auto 15px;
    display: block;
  }

  .icon-check {
    font-size: 8.1875rem;
    color: $primary;
  }

  .success-title {
    text-align: center;
    max-width: 230px;
    margin: 0 auto;
    font-size: 15px;
    font-style: normal;
    font-weight: bold;
    color: #292f3d;
  }

  .success-content {
    text-align: center;
    font-size: 14px;
    margin-bottom: 30px;
  }

  .button-list {
    padding: 10px 0 0;
  }
}

.success-button-list, .error-button-list {
  position: absolute;
  width: 100%;
  bottom: 0;
  left: 0;
  padding: 20px 10px 40px;
}

.error {
  width: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 30px;
  text-align: center;

  img {
    width: 70px;
    margin: 0 auto 15px;
    display: block;
  }

  .icon-error {
    font-size: 8.1875rem;
    color: #FF2E2E;
  }

  .error-title {
    text-align: center;
    max-width: 230px;
    margin: 0 auto;
    font-size: 15px;
    font-style: normal;
    font-weight: bold;
    color: #292f3d;
  }
}

.screen-select {
  .banner-item {
    width: 100%;

    &:nth-child(3) {
      margin-bottom: -3px;
      padding-bottom: 0;
    }

    .banner-image-container {
      height: 100%;
      padding: 25px 25px 19px;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;
      border-radius: 0;

      .banner-content {
        display: flex;
        flex-direction: column;
        height: 100%;

        h2 {
          font-size: 20px;
          font-style: normal;
          font-weight: bold;
          line-height: 27px;
          color: #fefefe;
          text-align: left;
          max-width: 229px;
          padding-top: 34px;
        }

        button {
          margin-top: auto;
          margin-bottom: 0;

          &.small {
            max-width: 149px;
          }
        }
      }
    }
  }
}

.screening-form {
  padding-top: 25px;

  .checkbox-list {
    .checkbox-group {
      box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.05);
      padding: 10px 5px 10px 10px;

      .checkbox {
        padding: 8px;
      }

      .checkbox.Mui-checked {
        color: $white;
      }

      .MuiTypography-body1 {
        font-size: 15px;
        font-weight: normal;
        font-style: normal;
        padding-left: 8px;
      }

      .MuiFormControlLabel-root {
        margin: 0 auto;
      }
    }

    .button-list {
      @include button-list();
      padding-top: 135px;
    }
  }
}

.referral-form {
  padding: 40px 8px 0;

  .indent-remove {
    .mobile-number-input {
      input {
        text-indent: initial;
      }
    }
  }

  .error-message {
    display: block;
    margin: 0 15px 25px;
    font-style: italic;
    font-weight: normal;
    font-size: 15px;
    color: #FF2E2E;
  }

  .select-arrow-icon-border {
    padding: 20px 24px;
    border-radius: 5px;
    border-left: 1px solid #bbbbbb;
  }

  .button-list {
    @include button-list();
  }

  .mobile-prefix {
    position: absolute;
    margin-top: 42px;
    margin-left: 10px;
    color: #454545;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 20px;
  }

  .mobile-number-input {
    input {
      text-indent: 40px;
    }
  }
}

.menu-list-wrapper {
  width: 100%;
  background: #ffffff;
  border: 1px solid #bbbbbb;
  border-radius: 0px 0px 5px 5px;
  box-shadow: none;
  font-style: normal;
  font-weight: 800;
  font-size: 12px;
  line-height: 16px;
  max-width: calc(100% - 55px);
  left: 27px !important;
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none; /* Safari and Chrome */
  }

  &.height150 {
    max-height: 150px;
  }

  &.height223 {
    max-height: 223px;
  }

  &.menu-item-facility {
    .menu-item-wrapper {
      align-items: end;

      span {
        padding-left: 9px;
      }

      .facility-info {
        color: $text;
        span {
          display: block;
          white-space: normal;
        }

        .address,
        .operating-hours {
          font-style: normal;
          font-weight: normal;
          font-size: 12px;
          line-height: 16px;
          text-transform: none;
        }
      }
    }
  }

  .menu-item-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;

    span {
      padding-left: 10px;
      font-style: normal;
      font-weight: 800;
      font-size: 12px;
      line-height: 16px;
      text-transform: uppercase;
    }

    svg.icon-circle {
      color: #828282;
    }
  }
}

.confirm-details {
  padding: 40px 8px 0;

  .button-list {
    @include button-list();
  }

  .direction {
    display: block;
    font-style: italic;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    color: #828282;
    padding-bottom: 25px;
  }

  .detail-item {
    padding-bottom: 10px;

    .field-title {
      font-style: normal;
      font-weight: bold;
      font-size: 12px;
      line-height: 16px;
      color: $primary;
      text-transform: uppercase;
    }

    p {
      font-style: normal;
      font-weight: normal;
      font-size: 15px;
      line-height: 20px;
      color: $text;
    }

    .facility-name {
      font-style: normal;
      font-weight: 800;
      font-size: 12px;
      text-transform: uppercase;
    }

    .facility-info {
      line-height: 26px;
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
    }
  }
}

.text-capitalize {
  text-transform: capitalize;
}

.text-uppercase {
  text-transform: uppercase;
}

/* Material UI Override */
.MuiAppBar-colorPrimary {
  color: $text;
  background: $white;
}

.MuiButton-root {
  text-transform: none;
}

.MuiCheckbox-root {
  color: #bdbdbd;

  .MuiSvgIcon-root {
    height: 18px;
    width: 18px;
  }
}

.MuiButton-root.Mui-disabled {
  color: $white;
  opacity: 0.3;
}

.MuiFormLabel-root {
  &.Mui-error {
    color: #FF2E2E !important;
  }
}

.MuiOutlinedInput-input {
  padding: 11px 10px;
  border: 1px solid #bbbbbb;
  border-radius: 5px;
}

.MuiOutlinedInput-root {
  border-radius: 5px;

  &.Mui-error > .MuiOutlinedInput-notchedOutline {
    border-color: transparent;
  }

  .MuiOutlinedInput-notchedOutline {
    border-color: transparent;
  }

  &.Mui-focused {
    .MuiOutlinedInput-notchedOutline {
      border-color: transparent;
      border-width: 1px;
    }
  }

  &:hover {
    .MuiOutlinedInput-notchedOutline {
      border-color: transparent;
    }
  }
}

.MuiInputLabel-outlined {
  transform: none;
}

.MuiInputLabel-formControl {
  top: 0;
  left: 0;
  position: relative;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: -0.165px;
  text-transform: uppercase;
  color: $text;
  padding-bottom: 15px;

  &.Mui-focused {
    color: $text;
  }
}

.MuiInputBase-root {
  color: #454545;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 20px;

  &:focus {
    outline: none;
  }

  &:hover {
    outline: none;
    border-color: #bbbbbb;
  }
}

.MuiSelect-iconOutlined {
  right: 12px;
}

.MuiSelect-outlined.MuiSelect-outlined {
  padding-right: 32px;

  &:after {
    content: '';
    width: 48px;
    height: 42px;
    right: 0;
    position: absolute;
    display: block;
    top: 0;
    border-radius: 5px;
    border-left: 1px solid #bbbbbb;
  }
}

.MuiSelect-select {
  &:focus {
    border-radius: 5px;
    background-color: $white;
  }

  .menu-item-wrapper {
    & > svg.icon-circle {
      display: none;
    }

    .facility-info {
      .address,
      .operating-hours {
        display: none;
      }
    }
  }
}

.MuiSelect-iconOpen {
  transform: none;
}

.MuiPopover-root {
  background-color: rgba(0, 0, 0, 0.3);
}

@keyframes slideFromBottom {
  0% {
    opacity: 0;
    transform: translateY(100%);
  }
  100% {
    opacity: 1;
    transform: none;
  }
}

.history-list {
  position: relative;
  width: 100%;
  min-height: 600px;
  height: 800px;
}

.history-item {
  cursor: pointer;
}

.history-list-item-primary {
  color: #454545;
  font-size: 15px;
  font-style: normal;
  font-weight: normal;
  font-family: 'Nunito Sans';
}

.history-list-item-secondary {
  font-size: 15px;
  line-height: 20px;
  font-style: normal;
  font-weight: bold;
  font-family: 'Nunito Sans';
}

.history-list-item-divider {
  margin: 0 15px 0 15px;
}

.history-filter-container {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  width: 100%;
}

.history-date-filter-container {
  margin: 10px 0 0 0;
}

.history-filter-item {
  margin: 5px;
  text-align: center;
  font-weight: 600;
}

.history-menu-container {
  width: 100%;
  background: #f9f9f9;
  position: relative;
  padding: 20px 5px 5px 4px;
}

.history-status-container {
  position: absolute;
  height: 21px;
  color: #ffff;
  border-radius: 5px;
  padding: 3px 5px 0 5px;
  text-align: center;
  right: 5px;
  font-family: 'Nunito Sans';
  font-style: normal;
  font-size: 12px;
  font-weight: 700;
  margin: 6px;
}

.warning-popup {
  .MuiDialog-container {
    .MuiPaper-root {
      border-radius: 10px;
      box-shadow: none;
      margin: 24px;
    }

    .MuiDialogTitle-root > h2 {
      font-style: normal;
      font-weight: 800;
      font-size: 20px;
      line-height: 35px;
      color: $text;
    }

    .MuiDialogContent-root > p {
      font-style: normal;
      font-weight: normal;
      font-size: 15px;
      color: $text;
    }

    .MuiDialogActions-root {
      justify-content: space-around;

      .popup-button {
        font-style: normal;
        font-weight: 800;
        font-size: 14px;
        line-height: 19px;
        text-transform: uppercase;
        color: $text;
      }

      .danger { color: #FF2E2E; }
    }
  }
}